import React, {Component} from "react";
import Main from '../../../components/frontend/Index/Main';
import About from '../../../components/frontend/Index/About';
import Portfolio from '../../../components/frontend/Index/Portfolio';
import Achievement from '../../../components/frontend/Index/Achievement';
import Certificates from '../../../components/frontend/Index/Certificates';
import Sport from '../../../components/frontend/Index/Sport';
// import asyncComponent from "../../../hoc/asyncComponent/asyncComponent";

import Lottie from "react-lottie";
import * as Loader from "../../../assets/loader";

// import Services from '../../../components/frontend/Index/Services';

// const asyncSections = asyncComponent(() => {
//     return import("./Sections");
// });

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

class Index extends Component {
    state = {
        sectionShow: false,
        loaderShow: true
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                sectionShow: true,
                loaderShow: false
            });
        }, 1000);
    }

    renderSections = () => {
        return (
            <React.Fragment>
                <About/>
                <Achievement/>
                <Portfolio/>
                <Certificates/>
                <Sport/>
            </React.Fragment>
        )
    };

    render() {
        return (
            <React.Fragment>
                {this.state.loaderShow
                    ? (
                        <div className="LoadingPage">
                            <Lottie options={defaultOptions} height={120} width={400}/>
                        </div>
                    )
                    :
                    (
                        <React.Fragment>
                            <Main/>
                            <About/>
                            <Achievement/>
                            <Portfolio/>
                            <Certificates/>
                            <Sport/>
                        </React.Fragment>

                    )
                }

                {/*{*/}
                {/*    this.state.sectionShow && this.renderSections()*/}
                {/*}*/}
            </React.Fragment>
        )
    }
}

export default Index;
