import React from "react";
import Slider from "react-slick";
import Image from "../UI/Image";

const CustomSlider = ({
    header,
    data,
    settings = {},
    custom = false,
    className = "Slider"
}) => {
    return (
        <div className={className}>
            {/*<h2 className={`slider__title`}>{header}</h2>*/}
            <Slider className={`${className}__slider`} {...settings}>
                {custom
                    ? data
                    : data.map(({ image, text }) => (
                          <div className={`${className}__item`}>
                              <Image
                                  src={image}
                                  alt={text}
                                  className={`${className}__image`}
                              ></Image>
                              <p className={`${className}__text`} title={text}>
                                  {text}
                              </p>
                          </div>
                      ))}
            </Slider>
        </div>
    );
};

export default CustomSlider;
