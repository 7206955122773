import * as actionTypes from '../actions/actionTypes';
import {mergeObject} from '../../shared/utility';

const initialState = {
    token: null,
    userID: null,
    userData: null,
    error: null,
    loading: false,
    authRedirectPath: '/'
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_REGISTER_START:
            //Start
            return authRegisterStart(state, action);
        case actionTypes.AUTH_REGISTER_SUCCESS:
            //Success
            return authRegisterSuccess(state, action);
        case actionTypes.AUTH_REGISTER_FAIL:
            //Fail
            return authRegisterFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            //Logout
            return authLogout(state, action);
        case actionTypes.AUTH_CLEAR_ERROR:
            //Logout
            return authClearError(state, action);
        case actionTypes.AUTH_REGISTER_FINISH:
            //Logout
            return authRegisterFinish(state, action);
        // case actionTypes.AUTH_LOGIN_START:
        //     //Login
        //     return authLoginStart(state, action);
        default:
            return state;
    }
};

const authRegisterStart = (state, action) => {
    return mergeObject(state, {
        loading: true
    })
};

const authRegisterFinish = (state, action) => {
    return mergeObject(state, {
        loading: false,
        error: null
    })
};

const authRegisterSuccess = (state, action) => {
    return mergeObject(state, {
        loading: false,
        error: null,
        userID: action.userID,
        token: action.token,
        userData: action.userData
    });
};

const authRegisterFail = (state, action) => {
    return mergeObject(state, {
        loading: false,
        error: action.error
    });
};


const authClearError = (state, action) => {
    return mergeObject(state, {
        loading: false,
        error: null
    });
};

const authLogout = (state, action) => {
    return mergeObject(state, {
        token: null,
        userID: null,
        userData: null,
        error: null,
        loading: false,
        authRedirectPath: '/'
    });
};

// const authLoginStart = (state, action) => {
//     return mergeObject(state, {
//         loading: true
//     });
// };

export default reducer;