import axios from "axios";
import { baseURL } from "../shared/variables";

// const headers = {};

// if (localStorage.getItem("token")) {
//     headers.Authorization = "Bearer " + localStorage.getItem("token");
// }

const instance = axios.create({
    baseURL
});

export default instance;
