import { delay } from "redux-saga/effects";
import { put } from "redux-saga/effects";
import axios from "../../axios/axios-def";
// import axios from 'axios';
import * as actions from "../actions/index";

export function* logoutSaga(action) {
    yield localStorage.removeItem("userID");
    yield localStorage.removeItem("token");
    yield localStorage.removeItem("userData");
    yield localStorage.removeItem("expirationDate");

    console.log("test", action);

    yield put(actions.authLogoutSuccess());
}

export function* checkTimeOutSaga(action) {
    yield delay(action.expirationTime * 1000);
    yield put(actions.authLogout());
}

export function* registerSaga(action) {
    yield put(actions.authRegisterStart());
    const {
        address,
        company,
        fio,
        is_accepted,
        mobile_phone,
        otp,
        password,
        role
    } = action.formData;

    let url = `/${action.formData.baseURL}`;
    // if (!action.formData.isSignIn) {
    //     url = "/register";
    // }

    try {
        // const config = {
        //     headers: { Accept: "application/json" }
        // };

        const result = yield axios.post(
            url,
            {
                address,
                company,
                fio,
                is_accepted,
                mobile_phone,
                otp,
                password,
                role
            },
            {}
        );
        // result.data.data.expiresIn = 3600; // from server
        // result.data.data.lang = 'ru';

        console.log("[registerSaga][Success]", result.data);

        const expirationDate = new Date(
            new Date().getTime() + result.data.expiresIn * 1000
        );

        yield localStorage.setItem("userID", result.data.id);
        yield localStorage.setItem("token", result.data.token);
        yield localStorage.setItem("userData", JSON.stringify(result.data));
        yield localStorage.setItem("expirationDate", expirationDate);
        yield localStorage.setItem("lang", "ru");

        yield put(
            actions.authRegisterSuccess(
                result.data.id,
                result.data.token,
                result.data
            )
        );
        yield put(actions.authCheckTimeout(result.data.expiresIn));
    } catch (error) {
        console.log("[registerSaga][Fail]", error.response.data.error);
        yield put(actions.authRegisterFail(error.response.data.error));
    }
}

// export function* registerSmsSaga(action) {
//     yield put(actions.authRegisterStart());
//
//     yield put(actions.authRegisterFail('не верный смс код'));
//
//     // let url = '/login';
//     //
//     // try {
//     //     const result = yield axiosDef.post(url, action.formData).data;
//     //
//     //     const result = {
//     //         "data": {
//     //             "expiresIn" : 3600,
//     //             "id": 62,
//     //             "token": "bElzOTB2azFDZ3M0QUV1c3FPc2ZBaGlhaEVMV3h1TjJaZnhUZ1dkQ2JJVkdGMnNDSXdJVFRRYWVFMVJy5c6d12143e33f",
//     //             "created_at": "2019-02-20 08:38:44",
//     //             "updated_at": "2019-02-20 08:38:44",
//     //             "profile": {
//     //                 "fio": "Bill Gates",
//     //                 "company": "USA",
//     //                 "address": "California",
//     //                 "mobile_phone": "+77076060606",
//     //                 "work_phone": "+77076060607",
//     //                 "email": "billgates01@gmail.com",
//     //                 "iin": "971234342134"
//     //             },
//     //             "roles": [
//     //                 {
//     //                     "name": "PRODUCER",
//     //                     "slug": "producer",
//     //                     "menus": [
//     //                         {
//     //                             "id": 1,
//     //                             "title": "EdaVoda",
//     //                             "slug": "edavoda",
//     //                             "url": "edavoda/index",
//     //                             "icon": "edavoda.png",
//     //                             "sortOrder": 1,
//     //                             "parent_id": 0,
//     //                             "child": [
//     //                                 {
//     //                                     "id": 3,
//     //                                     "title": "Products",
//     //                                     "slug": "products",
//     //                                     "url": "products/index",
//     //                                     "icon": "products.png",
//     //                                     "sortOrder": 1,
//     //                                     "parent_id": 1,
//     //                                     "child": [
//     //                                         {
//     //                                             "id": 5,
//     //                                             "title": "SubProducts",
//     //                                             "slug": "subproducts",
//     //                                             "url": "sub",
//     //                                             "icon": "sub.png",
//     //                                             "sortOrder": 1,
//     //                                             "parent_id": 3
//     //                                         }
//     //                                     ]
//     //                                 },
//     //                                 {
//     //                                     "id": 4,
//     //                                     "title": "Statistics",
//     //                                     "slug": "statistics",
//     //                                     "url": "statistics/index",
//     //                                     "icon": "statistics.png",
//     //                                     "sortOrder": 2,
//     //                                     "parent_id": 1
//     //                                 }
//     //                             ]
//     //                         },
//     //                         {
//     //                             "id": 2,
//     //                             "title": "Choco",
//     //                             "slug": "choco",
//     //                             "url": "choco/index",
//     //                             "icon": "choco.png",
//     //                             "sortOrder": 2,
//     //                             "parent_id": 0
//     //                         }
//     //                     ]
//     //                 }
//     //             ]
//     //         }
//     //     };
//     //
//     //     const expirationDate = new Date(new Date().getTime() + (result.data.expiresIn * 1000));
//     //
//     //     yield localStorage.setItem('userID', result.data.id);
//     //     yield localStorage.setItem('token', result.data.token);
//     //     yield localStorage.setItem('userData', result.data);
//     //     yield localStorage.setItem('expirationDate', expirationDate);
//     //
//     //     yield put(actions.authRegisterSuccess(result.data.id, result.data.token, result.data));
//     //     yield put(actions.authCheckTimeout(result.data.expiresIn));
//     // } catch (error) {
//     //     yield put(actions.authRegisterFail(error.response.data.error));
//     // }
// }

export function* checkStateSaga(action) {
    const token = yield localStorage.getItem("token");

    if (!token) {
        yield put(actions.authLogout());
    } else {
        const expirationDate = yield new Date(
            localStorage.getItem("expirationDate")
        );

        if (expirationDate <= new Date()) {
            yield put(actions.authLogout());
        } else {
            const userID = yield localStorage.getItem("userID");
            const userData = yield JSON.parse(localStorage.getItem("userData"));

            yield put(actions.authRegisterSuccess(userID, token, userData));
            yield put(
                actions.authCheckTimeout(
                    (expirationDate.getTime() - new Date().getTime()) / 1000
                )
            );
        }
    }
}

export function* smsSendSaga(action) {
    console.log(action);
    yield put(actions.authRegisterStart());

    let url = action.formData.baseURL;

    if (action.formData.form === "sendotp") {
        url = `/${url}/sendotp`;
    }

    try {
        const result = yield axios.post(url, action.formData);

        console.log("[smsSendSaga]", result);

        yield put(actions.authRegisterFinish());
    } catch (error) {
        yield put(actions.authRegisterFail(error.response.data.error));
        yield put(actions.authRegisterFinish());
    }
}
