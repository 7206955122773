import React, {useState} from "react";

import Drawer from "../SideDrawer/DrawerToggle/DrawerToggle";
import Navigation from "../NavigationItems/NavigationItems";
// import Logo from "../../Logo/Logo";

const toolbar = ({
                     // handleDrawerClick,
                     isAuth,
                     handleLoginClick,
                 }) => {

    const [showMenu, setShowMenu] = useState(false);

    const handleDrawerToggle = () => {
        console.log("action close");
        setShowMenu(prevState => !prevState);
    };

    return (
        <header>
            <Drawer showMenu={showMenu} clicked={handleDrawerToggle}/>

            <div className={["Toolbar", showMenu ? "show" : ""].join(' ')}>
                <div className={["Toolbar__branding", showMenu ? "show" : ""].join(' ')}>
                    <div className="Toolbar__portrait">
                        <img className="Toolbar__img" src={"/assets/avatar.png"} alt="Илиев Тельман"/>
                    </div>
                </div>

                <Navigation
                    showMenu={showMenu}
                    closeToolbar={handleDrawerToggle}
                    //Toolbar__list
                    isAuth={isAuth}
                    loginClicked={handleLoginClick}
                />
            </div>
        </header>
    );
};

export default toolbar;
