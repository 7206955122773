import React, {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImages, faLink} from '@fortawesome/free-solid-svg-icons';
import Modal from "../../UI/Modal/modalFullScreen/modalFullScreen";
import Slider from "../../Index/Slider";
import ReviewsItem from "../../../components/Index/ReviewsItem";
import Spinner from "../../UI/Spinner/Spinner";

const Achievement = () => {
    const [modalShow, setModalShow] = useState(false);
    const [project, setProject] = useState(null);

    const showModal = (project_name) => {
        setProject(project_name);
        setModalShow(prevState => !prevState);
    };

    const closeModal = () => {
        setModalShow(false);
    };

    const generateSlider = () => {
        switch (project) {
            case "astanahub":
                return (
                    <Slider
                        custom
                        settings={{
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplaySpeed: 3000,
                            dots: true,
                            infinite: true,
                            centerMode: false
                        }}
                        header=""
                        className="Slider"
                        data={[
                            <ReviewsItem
                                key={1}
                                image="/assets/achievement/ah_scan_new.jpg"
                                heading="Astana HUB 1"
                            />,
                            <ReviewsItem
                                key={2}
                                image="/assets/achievement/ah_1.jpg"
                                heading="Astana HUB 2"
                            />,
                            <ReviewsItem
                                key={3}
                                image="/assets/achievement/ah_2.jpg"
                                heading="Astana HUB 3"
                            />,
                            <ReviewsItem
                                key={4}
                                image="/assets/achievement/ah_3.jpg"
                                heading="Astana HUB 4"
                            />,
                        ]}
                    />
                );
            case "darlab":
                return (
                    <Slider
                        custom
                        settings={{
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplaySpeed: 3000,
                            dots: true,
                            infinite: true,
                            centerMode: false
                        }}
                        header=""
                        className="Slider"
                        data={[
                            <ReviewsItem
                                key={1}
                                image="/assets/achievement/dar_3.jpg"
                                heading="DARLab 3"
                            />,
                            <ReviewsItem
                                key={2}
                                image="/assets/achievement/dar_1.jpg"
                                heading="DARLab 1"
                            />,
                            <ReviewsItem
                                key={3}
                                image="/assets/achievement/dar_4.jpeg"
                                heading="DARLab 4"
                            />,
                            <ReviewsItem
                                key={4}
                                image="/assets/achievement/dar_5.jpeg"
                                heading="DARLab 4"
                            />,
                        ]}
                    />
                );
            case "db":
                return (
                    <Slider
                        custom
                        settings={{
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplaySpeed: 3000,
                            dots: true,
                            infinite: true,
                            centerMode: false
                        }}
                        header=""
                        className="Slider"
                        data={[
                            <ReviewsItem
                                key={3}
                                image="/assets/achievement/db_3.jpg"
                                heading="Digital Bridge 3"
                            />,
                            <ReviewsItem
                                key={2}
                                image="/assets/achievement/db_2.jpg"
                                heading="Digital Bridge 2"
                            />,
                            <ReviewsItem
                                key={1}
                                image="/assets/achievement/db_1.jpg"
                                heading="Digital Bridge 1"
                            />,
                            <ReviewsItem
                                key={4}
                                image="/assets/achievement/db_4.jpg"
                                heading="Digital Bridge 4"
                            />,
                        ]}
                    />
                );
        }
    };

    return (
        <React.Fragment>
            <section className="Achievement" id="achievement">
                {modalShow && (
                    <Modal show={modalShow} closed={closeModal}>
                        <div className="Achievement__modal">
                            {generateSlider()}
                        </div>
                    </Modal>
                )}

                <div className="Inner-section">
                    <div className="section-heading">
                        <h1>Достижения</h1>
                        {/*<h6>Некоторые проекты</h6>*/}
                    </div>

                    <div className="Achievement__inner">
                        <ul className="Achievement__list">
                            <li className="Achievement__item">
                                <div className="card">
                                    <div className="card-header"
                                         style={{"backgroundImage": "url(/assets/achievement/ah_1.jpg)"}}>
                                        <img className="card-header__img" src={"/assets/achievement/ah_1.jpg"}
                                             alt="Astana HUB"/>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-content__pick"
                                             style={{"backgroundImage": "url(/assets/achievement/ah_logo.png)"}}>
                                            <img className="card-content__img" src={"/assets/achievement/ah_logo.png"}
                                                 alt="Astana HUB"/>
                                        </div>
                                        <div className="card-content__desc">
                                            Резидент Astana Hub.
                                            Проект EdaVoda.kz прошел отбор на бесплатную акселерацию в международном
                                            технопарке IT-стартапов.
                                        </div>

                                        <div className="card-resources">
                                            <ul className="card-resources__list">
                                                <li
                                                    onClick={() => showModal("astanahub")}
                                                    className="card-resources__item card-resources__item--gallery"
                                                >
                                                    <FontAwesomeIcon className="card-resources__icon" icon={faImages}/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="Achievement__item">
                                <div className="card">
                                    <div className="card-header"
                                         style={{"backgroundImage": "url(/assets/achievement/saby_1.png)"}}>
                                        <img className="card-header__img" src={"/assets/achievement/saby_1.png"}
                                             alt="Построй свой бизнес"/>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-content__pick"
                                             style={{"backgroundImage": "url(/assets/achievement/saby_logo.svg)"}}>
                                            <img className="card-content__img" src={"/assets/achievement/saby_logo.svg"}
                                                 alt="Astana HUB"/>
                                        </div>
                                        <div className="card-content__desc">
                                            Финалист конкурса "Построй свой бизнес" 2015/2016 г.<br/>
                                            Основатель vseprofi.kz (Проект продан в 2017 году).
                                        </div>

                                        <div className="card-resources">
                                            <ul className="card-resources__list">
                                                <li className="card-resources__item card-resources__item--link">
                                                    <a className="card-resources__href"
                                                       href="https://entrepreneur.saby.kz/projects/2016/1502"
                                                       target="_blank">
                                                        <FontAwesomeIcon className="card-resources__icon"
                                                                         icon={faLink}/>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="Achievement__item">
                                <div className="card">
                                    <div className="card-header"
                                         style={{"backgroundImage": "url(/assets/achievement/dar_4.jpeg)"}}>
                                        <img className="card-header__img" src={"/assets/achievement/dar_4.jpeg"}
                                             alt="Dar Lab"/>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-content__pick"
                                             style={{"backgroundImage": "url(/assets/achievement/dar_logo.png)"}}>
                                            <img className="card-content__img" src={"/assets/achievement/dar_logo.png"}
                                                 alt="Dar Lab"/>
                                        </div>
                                        <div className="card-content__desc">
                                            Член жюри хакатона DAR Lab.
                                            Состав экспертной комиссии: представители топовых ИТ компании РК (Крыша
                                            Колеса, Crystal Spring, DAR)
                                        </div>

                                        <div className="card-resources">
                                            <ul className="card-resources__list">
                                                <li
                                                    onClick={() => showModal("darlab")}
                                                    className="card-resources__item card-resources__item--gallery"
                                                >
                                                    <FontAwesomeIcon className="card-resources__icon" icon={faImages}/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="Achievement__item">
                                <div className="card">
                                    <div className="card-header"
                                         style={{"backgroundImage": "url(/assets/achievement/db_1.jpg)"}}>
                                        <img className="card-header__img" src={"/assets/achievement/db_1.jpg"}
                                             alt="Dar Lab"/>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-content__pick"
                                             style={{"backgroundImage": "url(/assets/achievement/db_logo.png)"}}>
                                            <img className="card-content__img" src={"/assets/achievement/db_logo.png"}
                                                 alt="Dar Lab"/>
                                        </div>
                                        <div className="card-content__desc">
                                            Участник международного технологического форума "Digital Bridge", уровень доступа Пресса
                                        </div>

                                        <div className="card-resources">
                                            <ul className="card-resources__list">
                                                <li
                                                    onClick={() => showModal("db")}
                                                    className="card-resources__item card-resources__item--gallery"
                                                >
                                                    <FontAwesomeIcon className="card-resources__icon" icon={faImages}/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </React.Fragment>

    )
};

export default Achievement;
