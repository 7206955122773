import React from "react";

const ReviewsItem = ({image, alt, title, desc, link}) => {
    return (
        <div className="Slider-cert__item">
            <div className="card">
                <div className="card-header"
                     style={{"backgroundImage": `url(${image})`}}>
                    <img className="card-header__img" src={image}
                         alt={alt}/>
                </div>

                <div className="card-content">
                    <div className="card-content__title">
                        {title}
                    </div>
                    <div className="card-content__desc">
                        {desc}
                    </div>
                    <div className="card-content__options">
                        <a className="card-content__link"
                           href={link}
                           target={"_blank"}
                        >
                            Посмотреть на сайте
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewsItem;
