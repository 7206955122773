import React from "react";

const ReviewsItem = ({ heading, image }) => {
    return (
        <div className="Slider__item" style={{"backgroundImage":`url(${image})`}}>
            <img src={image} alt={heading} className="Slider__image" />
        </div>
    );
};

export default ReviewsItem;
