import React from "react";

class About extends React.Component {

    randomInteger = (min, max) => {
        let rand = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
    };

    //Ключевык навыки, hover effect
    stackHoverEffect = () => {
        const stackList = document.querySelectorAll('.About__item-inner');

        let pervNumber = 0;
        setInterval(() => {
            const number = this.randomInteger(0, stackList.length - 1);
            stackList.forEach((stack, index) => {
                stack.style.opacity = '0.3';

                if (index === number && number !== pervNumber) {
                    stack.style.opacity = '1';
                }
            });
            pervNumber = number;
        }, 1500);
    };

    componentDidMount() {
        this.stackHoverEffect();
    }

    render() {

        return (

            <section className="About" id="about">

                <div className="About__heading">
                    {/*<h1>Не много</h1>*/}
                    {/*<h6>О себе</h6>*/}
                    <h1>О себе</h1>
                </div>

                <div className="About__inner">
                    <div className="About__description">
                        <p>
                            Привет!
                        </p>
                        <p>
                            Меня зовут Тельман, я Full Stack разработчик. Более 7 лет занимался разработкой и
                            автоматизицией
                            бизнес процессов.
                            С 2018 года руководитель отдела разработки в Startup Edavoda.kz, наш проект в
                            марте
                            2019 года успешно прошел этапы экспертизы и стал грантополучателем ЕБРР (Европейский банк
                            реконструкции и развития).
                        </p>
                    </div>
                    <div className="About__portrait">
                        <div className="About__pick" style={{"backgroundImage": "url(/assets/me/pic_2.jpg)"}}>
                            <img className="About__pick-img" src={"/assets/me/pic_2.jpg"} alt="Илиев Тельман"/>
                        </div>
                    </div>
                    <div className="About__content">

                        <div className="About__details">
                            <div className="About__skills">
                                <div className="About__skills-title">Ключевые навыки</div>
                                <ul className="About__list">
                                    <li className="About__item About__item--right_none">
                                        <div className="About__type">Frontend:</div>
                                        <ul className="About__list-inner">
                                            {/*<li className="About__item-inner About__item-inner--html">*/}
                                            {/*    html5*/}
                                            {/*</li>*/}
                                            {/*<li className="About__item-inner About__item-inner--css">*/}
                                            {/*    css3*/}
                                            {/*</li>*/}
                                            <li className="About__item-inner About__item-inner--js">
                                                js/es6
                                            </li>
                                            <li className="About__item-inner About__item-inner--react">
                                                reactjs
                                            </li>

                                            <li className="About__item-inner About__item-inner--sass">
                                                sass
                                            </li>
                                            <li className="About__item-inner About__item-inner--bem">
                                                bem
                                            </li>
                                        </ul>
                                    </li>
                                    {/*<li className="About__item About__item--right_none">*/}
                                    {/*    <div className="About__type">Backend:</div>*/}
                                    {/*    <ul className="About__list-inner">*/}
                                    {/*        <li className="About__item-inner About__item-inner--php">*/}
                                    {/*            php*/}
                                    {/*        </li>*/}
                                    {/*        <li className="About__item-inner About__item-inner--laravel">*/}
                                    {/*            laravel*/}
                                    {/*        </li>*/}
                                    {/*        <li className="About__item-inner About__item-inner--mysql">*/}
                                    {/*            mysql*/}
                                    {/*        </li>*/}
                                    {/*        <li className="About__item-inner About__item-inner--nginx">*/}
                                    {/*            nginx*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}

                                    {/*<li className="About__item">*/}
                                    {/*    <div className="About__type">CI/CD && IaC:</div>*/}
                                    {/*    <ul className="About__list-inner">*/}
                                    {/*        <li className="About__item-inner About__item-inner--jenkins">*/}
                                    {/*            jenkins*/}
                                    {/*        </li>*/}
                                    {/*        <li className="About__item-inner About__item-inner--git">*/}
                                    {/*            git*/}
                                    {/*        </li>*/}
                                    {/*        <li className="About__item-inner About__item-inner--sass">*/}
                                    {/*            codepipline*/}
                                    {/*        </li>*/}
                                    {/*        <li className="About__item-inner About__item-inner--bem">*/}
                                    {/*            terraform*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}
                                    <li className="About__item About__item--right_none">
                                        <div className="About__type">Cloud & Other:</div>
                                        <ul className="About__list-inner">
                                            <li className="About__item-inner About__item-inner--aws">
                                                aws
                                            </li>
                                            <li className="About__item-inner About__item-inner--linux">
                                                linux
                                            </li>
                                            <li className="About__item-inner About__item-inner--codep">
                                                codepipline
                                            </li>
                                            <li className="About__item-inner About__item-inner--bem">
                                                terraform
                                            </li>
                                            {/*<li className="About__item-inner About__item-inner--sass">*/}
                                            {/*    sass*/}
                                            {/*</li>*/}
                                            {/*<li className="About__item-inner About__item-inner--bem">*/}
                                            {/*    bem*/}
                                            {/*</li>*/}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default About;
