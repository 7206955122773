import { parse } from "libphonenumber-js";

export const mergeObject = (oldState, updateElement) => {
    return {
        ...oldState,
        ...updateElement
    };
};

export const checkValidity = (value, rules) => {
    let isValid = true;

    // console.log('[checkValidity]', value.length);

    if (rules.require) {
        isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
        isValid =
            value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && isValid;
    }

    if (rules.isNum) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isPhone) {
        // console.log('[before]', value);
        let parseNumber = parse(value, "KZ");

        // console.log('[after]', parseNumber);

        // console.log('[Object.keys]', Object.keys(parseNumber));

        const count = Object.keys(parseNumber)
            .map(key => {
                return parseNumber[key];
            })
            .reduce((total, el) => {
                return total + 1;
            }, 0);

        // console.log('[count]', count);

        isValid = count >= 2 && isValid;
    }

    return isValid;
};

export const shortTitle = (title, titleLength = 5) => {
    let newTitle = [];

    if (title.length > titleLength) {
        title.split(" ").reduce((acc, cur) => {
            if (acc + cur.length < titleLength) {
                newTitle.push(cur);
            }

            return acc + cur.length;
        }, 0);

        return `${newTitle.join(" ")}...`;
    }

    return title;
};

export const formatNumber = num => {
    let numSplit, int, dec;
    /*
        + or - before number
        exactly 2 decimal points
        comma separating the thousands
        2310.4567 -> + 2,310.46
        2000 -> + 2,000.00
    */

    num = Math.abs(num);
    num = num.toFixed(2);

    numSplit = num.split(".");

    int = numSplit[0];
    if (int.length > 3 && int.length <= 6) {
        int =
            int.substr(0, int.length - 3) +
            "," +
            int.substr(int.length - 3, int.length); //input 23510, output 23,510
    } else if (int.length > 6) {
        int =
            int.substr(0, int.length - 6) +
            "," +
            int.substr(int.length - 6, 3) +
            "," +
            int.substr(int.length - 3, int.length); //input 23510, output 23,510
    }

    dec = numSplit[1];

    return `${int}`; //.${dec}
};

export const useValidation = (...fields) => {
    let valid = true;

    fields.forEach(field => {
        if (
            field === null ||
            field === undefined ||
            field === "" ||
            field === false
        ) {
            valid = false;
        }
    });

    return valid;
};

export const makeURL = url => params => {
    const keys = [];
    const values = [];

    Object.keys(params).forEach(param => {
        if (Array.isArray(params[param])) {
            params[param].forEach(p => {
                keys.push(`${param}[]`);
                values.push(p);
            });
        } else {
            keys.push(param);
            values.push(params[param]);
        }
    });

    console.log(keys, values);
    const paramsString = keys
        .map((key, i) => values[i] && `${key}=${values[i]}`)
        .filter(param => !!param);

    return paramsString.length > 0
        ? `/${url}?` + paramsString.reduce((acc, val) => `${acc}&${val}`)
        : "";
};

export const hasRole = (roles, roleToMatch) => {
    if (!roles) return false;
    return roles.find(role => role.title === roleToMatch);
};

export const declOfNum = (number, titles) => {
    var cases = [2, 0, 1, 1, 1, 2];
    return titles[
        number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[number % 10 < 5 ? number % 10 : 5]
    ];
};
