import React from 'react';

import Backdrop from "../../Backdrop/Backdrop";

const modalFullScreen = ({
                             show,
                             closed,
                             children
                         }) => {
    // Block Body Scroll
    document.querySelector("body").classList.add("modal-open");

    const modalClass = show ? 'Modal-Full-Screen--show' : "";

    return (
        <React.Fragment>
            <Backdrop show={show}/>
            <div className={['Modal-Full-Screen', modalClass].join(' ')}>
                <div className={'Modal-Full-Screen__close-element'} onClick={() => {
                    closed();
                    document.querySelector("body").classList.remove("modal-open");
                }}>
                    <span className="Modal-Full-Screen__icon">x</span>
                </div>
                {children}
            </div>
        </React.Fragment>
    );
};

export default modalFullScreen;