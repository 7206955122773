import React, { Component } from "react";
import { connect } from "react-redux";

import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
// import Login from "../../containers/frontend/Auth/Login/Login";

class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // showSideDrawer: false,
            showLoginForm: false
        };
    }

    // drawerToggleClickHandler = () => {
        // this.setState(prevState => {
        //     return {
        //         showSideDrawer: !prevState.showSideDrawer
        //     };
        // });
    // };

    loginClickHandler = () => {
        this.setState(
            prevState => {
                return {
                    showLoginForm: !prevState.showLoginForm
                };
            },
            () => {
                document.querySelector("input#inputPhoneLogin").focus();
            }
        );
    };

    loginFromCloseHandler = event => {
        event.preventDefault();

        this.setState({
            showLoginForm: false
        });
    };

    render() {
        return (
            <React.Fragment>
                {/*{!this.props.isAuth ? (*/}
                {/*    <Login*/}
                {/*        loginFormClose={this.loginFromCloseHandler}*/}
                {/*        isShow={this.state.showLoginForm}*/}
                {/*    />*/}
                {/*) : null}*/}

                <main className={"container"}>
                    <Toolbar
                        isAuth={this.props.isAuth}
                        // handleDrawerClick={this.drawerToggleClickHandler}
                        handleLoginClick={this.loginClickHandler}
                    />
                    {this.props.children}
                </main>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null
    };
};

export default connect(mapStateToProps)(Layout);
