import React, {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import Modal from "../../UI/Modal/modalFullScreen/modalFullScreen";

const Portfolio = () => {

    const [modalShow, setModalShow] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);

    const showModal = (image_url) => {
        setImageUrl(image_url);
        setModalShow(prevState => !prevState);
    };

    const closeModal = () => {
        setModalShow(false);
    };

    return (
        <React.Fragment>
            <section className="Portfolio" id="portfolio">
                {modalShow && (
                    <Modal show={modalShow} closed={closeModal}>
                        <div className="Portfolio__modal">
                            <div className="Portfolio__modal-pick"
                                 style={{'backgroundImage': `url(${imageUrl})`}}
                            >
                                <img className="Portfolio__modal-img"
                                     src={`${imageUrl}`}
                                     alt={"Рекомендательное письмо от ГК Алины, Илиеву Тельману"}
                                />
                            </div>
                        </div>
                    </Modal>
                )}

                <div className="Inner-section">
                    <div className="section-heading">
                        <h1>Портфолио</h1>
                        {/*<h6>Некоторые проекты</h6>*/}
                    </div>

                    <div className="Portfolio__inner">
                        <ul className="Portfolio__list">
                            <li className="Portfolio__item">
                                <div className="Portfolio__company">
                                    <h4 className="Portfolio__company-title">Группа Компаний «Alina»</h4>
                                    <div className="Portfolio__company-desc">
                                        Основанна в 1989 году – многоотраслевой холдинг, занимающийся развитием
                                        различных отраслей бизнеса.
                                        В состав ГК «Alina» входят: Oi-Qaragai Lesnaya Skazka, AlinEX, Alina Paint,
                                        DOMSAD
                                    </div>
                                </div>
                                <div className="Portfolio__line">Задачи</div>
                                <div className="Portfolio__tasks">
                                    <ul className="Portfolio__tasks-list">
                                        <li className="Portfolio__tasks-item">
                                            Разработка системы электронных закупок
                                        </li>
                                        <li className="Portfolio__tasks-item">
                                            Разработка системы протокол оценки поставшика
                                        </li>
                                        <li className="Portfolio__tasks-item">
                                            Разработка системы контроль входящих документов ЮД
                                        </li>
                                    </ul>
                                </div>
                                <div className="Portfolio__line">Рекомендательное письмо</div>
                                <div className="Portfolio__recommendation">
                                    <div className="Portfolio__recommendation-logo"
                                         style={{'backgroundImage': "url(/assets/portfolio/554403237.jpeg)"}}
                                         onClick={() => showModal("/assets/portfolio/554403237.jpeg")}
                                    >
                                        <div className="Portfolio__recommendation-icon">
                                            <FontAwesomeIcon icon={faSearch}/>
                                        </div>
                                        <img className="Portfolio__recommendation-pick"
                                             src={"/assets/portfolio/554403237.jpeg"}
                                             alt={"Рекомендательное письмо от ГК Алины, Илиеву Тельману"}
                                        />
                                    </div>
                                </div>
                            </li>
                            <li className="Portfolio__item">
                                <div className="Portfolio__company">
                                    <h4 className="Portfolio__company-title">Atasu Group</h4>
                                    <div className="Portfolio__company-desc">
                                        В активах группы железнодорожные, морские, авиа- и автоперевозки, сеть
                                        собственных терминалов.
                                        Грузооборот компании - 10 млн тонн в год.
                                    </div>
                                </div>
                                <div className="Portfolio__line">Задачи</div>
                                <div className="Portfolio__tasks">
                                    <ul className="Portfolio__tasks-list">
                                        <li className="Portfolio__tasks-item">
                                            Разработка online тарифного калькулятора
                                        </li>
                                        <li className="Portfolio__tasks-item">
                                            Внедрение системы отслеживания накладных
                                        </li>
                                        <li className="Portfolio__tasks-item">
                                            Разработка клиентской CRM
                                        </li>
                                    </ul>
                                </div>
                                <div className="Portfolio__line">Рекомендательное письмо</div>
                                <div className="Portfolio__recommendation">
                                    <div className="Portfolio__recommendation-logo"
                                         style={{'backgroundImage': "url(/assets/portfolio/553598643.jpeg)"}}
                                         onClick={() => showModal("/assets/portfolio/553598643.jpeg")}

                                    >
                                        <div className="Portfolio__recommendation-icon">
                                            <FontAwesomeIcon icon={faSearch}/>
                                        </div>
                                        <img className="Portfolio__recommendation-pick"
                                             src={"/assets/portfolio/553598643.jpeg"}
                                             alt={"Рекомендательное письмо от Atasu Group, Илиеву Тельману"}
                                        />
                                    </div>
                                </div>
                            </li>
                            <li className="Portfolio__item">
                                <div className="Portfolio__company">
                                    <h4 className="Portfolio__company-title">Жолашар - ЖД Сервис</h4>
                                    <div className="Portfolio__company-desc">
                                        ТОО ЖОЛАШАР-ЖД СЕРВИС основан 1 марта 1962 года. С 2006 года, явлется официальным экспедитором АО НК
                                        "Казахстан Темир Жолы"
                                    </div>
                                </div>
                                <div className="Portfolio__line">Задачи</div>
                                <div className="Portfolio__tasks">
                                    <ul className="Portfolio__tasks-list">
                                        <li className="Portfolio__tasks-item">
                                            Разработка модуля "Книга прибытия" вагонов
                                        </li>
                                        <li className="Portfolio__tasks-item">
                                            Разработка модуля "Ведомость"
                                        </li>
                                        <li className="Portfolio__tasks-item">
                                            Разработка модуля "Натурный лист"
                                        </li>
                                    </ul>
                                </div>
                                <div className="Portfolio__line">Рекомендательное письмо</div>
                                <div className="Portfolio__recommendation">
                                    <div className="Portfolio__recommendation-logo"
                                         style={{'backgroundImage': "url(/assets/portfolio/570040136.jpeg)"}}
                                         onClick={() => showModal("/assets/portfolio/570040136.jpeg")}
                                    >
                                        <div className="Portfolio__recommendation-icon">
                                            <FontAwesomeIcon icon={faSearch}/>
                                        </div>
                                        <img className="Portfolio__recommendation-pick"
                                             src={"/assets/portfolio/570040136.jpeg"}
                                             alt={"Рекомендательное письмо от Жолашар - ЖД Сервис, Илиеву Тельману"}
                                        />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </React.Fragment>

    )
};

export default Portfolio;
