import React from 'react';

import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = ({
                             isAuth,
                             showMenu,
                             closeToolbar
                         }) => {

    let links = (
        <React.Fragment>
            <NavigationItem showMenu={showMenu} closeToolbar={closeToolbar} link='#main'>
                Главная
            </NavigationItem>
            <NavigationItem showMenu={showMenu} closeToolbar={closeToolbar} link='#about'>
                О себе
            </NavigationItem>
            <NavigationItem showMenu={showMenu} closeToolbar={closeToolbar} link='#achievement'>
                Достижения
            </NavigationItem>
            <NavigationItem showMenu={showMenu} closeToolbar={closeToolbar} link='#portfolio'>
                Портфолио
            </NavigationItem>
            <NavigationItem showMenu={showMenu} closeToolbar={closeToolbar} link='#certificates'>
                Сертификаты
            </NavigationItem>
            <NavigationItem showMenu={showMenu} closeToolbar={closeToolbar} link='#sport'>
                Хобби
            </NavigationItem>
        </React.Fragment>
    );

    if (isAuth) {
        links = (
            <React.Fragment>
                <NavigationItem link='/dashboard'>Кабинет</NavigationItem>
                <NavigationItem link='/logout'>Выход</NavigationItem>
            </React.Fragment>
        )
    }

    return (
        <ul className={["Toolbar__list", showMenu ? "show" : ""].join(' ')}>
            {links}
        </ul>
    );
};

export default navigationItems;