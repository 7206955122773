import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import asyncComponent from "./hoc/asyncComponent/asyncComponent";
import "./scss/app.scss";

import Layout from "./hoc/Layouts/Layout";
// import logo from './logo.svg';
import Index from "./containers/frontend/Index/Index";
import * as actions from "./store/actions/index";

// import "react-datepicker/dist/react-datepicker.css";
// import "rodal/lib/rodal.css";

// import moment from "moment";
// import "moment/locale/ru";
// import { AdminRoutes } from "./routes/admin";
// import { roles } from "./shared/authRoles";
// import { hasRole } from "./shared/utility";
// import { ProducerRoutes } from "./routes/producer";
// import Logout from "./containers/frontend/Auth/Logout/Logout";

// moment.locale("ru");

// Async Component
// const asyncRegister = asyncComponent(() => {
//     return import("./containers/frontend/Auth/Register/Register");
// });

class App extends Component {
    componentDidMount() {
        this.props.onAuthCheckState();
    }

    render() {
        let routes = (
            <Layout>
                <Switch>
                    {/*<Route path={"/register"} exact component={asyncRegister} />*/}
                    {/*<Route path={"/logout"} exact component={Logout} />*/}
                    <Route path={"/"} exact component={Index} />
                    {/* <Redirect to={"/"} /> */}
                </Switch>
            </Layout>
        );

        if (!this.props.userData) return routes;

        // console.log("userData", this.props.userData);
        //
        // const findCurrentRole = hasRole.bind(null, this.props.userData.roles);
        //
        // if (
        //     this.props.userData.role &&
        //     this.props.userData.role.find(role => role.name === roles.customer)
        // ) {
        //     routes = (
        //         <Layout>
        //             <Switch>
        //                 <Route
        //                     path={"/"}
        //                     exact
        //                     component={() => (
        //                         <h1 className="index-message">
        //                             Пожалуйста, воспользуйтесь мобильным
        //                             приложением.
        //                         </h1>
        //                     )}
        //                 />
        //                 <Route path={"/logout"} exact component={Logout} />
        //                 <Redirect to={"/"} />
        //             </Switch>
        //         </Layout>
        //     );
        //
        //     return routes;
        // }

        // if (this.props.userData.is_verified == 0) {
        //     routes = (
        //         <Layout>
        //             <Switch>
        //                 <Route
        //                     path={"/"}
        //                     exact
        //                     component={() => (ç
        //                         <h1 className="index-message">
        //                             Ваш аккаунт не подтверждён.
        //                         </h1>
        //                     )}
        //                 />
        //                 <Route path={"/logout"} exact component={Logout} />
        //                 <Redirect to={"/"} />
        //             </Switch>
        //         </Layout>
        //     );
        // } else {
        //     if (this.props.isAuth) {
        //         if (findCurrentRole(roles.admin)) {
        //             routes = <AdminRoutes />;
        //         } else if (findCurrentRole(roles.producer)) {
        //             routes = <ProducerRoutes />;
        //         }
        //     }
        // }

        return routes;
    }
}

const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        userData: state.auth.userData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
