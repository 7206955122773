import React, {useState} from "react";
import CertItem from "../../Index/CertItem";
import Slider from "../../Index/Slider";
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faSearch} from '@fortawesome/free-solid-svg-icons';
// import Modal from "../../UI/Modal/modalFullScreen/modalFullScreen";

const Certificates = () => {
    let sliderSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
        centerMode: false
    };

    const clientWidth = document.documentElement.clientWidth;

   if (clientWidth < 1200 && clientWidth >= 750) {
        sliderSettings = {
            ...sliderSettings,
            slidesToShow: 2
        };
    } else if (clientWidth < 750) {
       sliderSettings = {
           ...sliderSettings,
           slidesToShow: 1
       };
    }

    return (
        <React.Fragment>
            <section className="Certificates" id="certificates">
                <div className="Inner-section">
                    <div className="section-heading">
                        <h1>Сертификаты</h1>
                        {/*<h6>Некоторые проекты</h6>*/}
                    </div>

                    <div className="Certificates__inner">
                        <Slider
                            custom
                            settings={sliderSettings}
                            header=""
                            className="Slider-cert"
                            data={[
                                <CertItem
                                    key={1}
                                    image="/assets/cert/tp_1.jpeg"
                                    alt="TP01834939"
                                    title="TestProvider.com"
                                    desc="Международный центр сертификации. Стандарты разработаны при участии 1C, Epam, Terrasoft, UOC"
                                    link="https://testprovider.com/ru/Certificate/Search/TP01834939"
                                />,
                                <CertItem
                                    key={2}
                                    image="/assets/cert/tp_2.jpeg"
                                    alt="TP39403139"
                                    title="TestProvider.com"
                                    desc="Международный центр сертификации. Стандарты разработаны при участии 1C, Epam, Terrasoft, UOC"
                                    link="https://testprovider.com/ru/Certificate/Search/TP39403139"
                                />,
                                <CertItem
                                    key={3}
                                    image="/assets/cert/m_1.png"
                                    alt="669333"
                                    title="Mail.ru Group"
                                    desc="Mail.ru Group — российская технологическая компания. Среди её активов социальные сети «ВКонтакте» и «Одноклассники»"
                                    link="https://geekbrains.ru/certificates/669333"
                                />,
                                <CertItem
                                    key={4}
                                    image="/assets/cert/m_2.png"
                                    alt="700175"
                                    title="Mail.ru Group"
                                    desc="Mail.ru Group — российская технологическая компания. Среди её активов социальные сети «ВКонтакте» и «Одноклассники»"
                                    link="https://geekbrains.ru/certificates/700175"
                                />,
                            ]}
                        />

                        {/*<div className="Slider-cert">*/}
                        {/*   */}
                        {/*    */}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        </React.Fragment>

    )
};

export default Certificates;
