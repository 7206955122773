import {takeEvery} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';

import {registerSaga, checkTimeOutSaga, logoutSaga, checkStateSaga, smsSendSaga} from './auth';

export function* watchAuth() {
    yield takeEvery(actionTypes.AUTH_REGISTER_INIT, registerSaga);
    // yield takeEvery(actionTypes.AUTH_REGISTER_SMS_INIT, registerSmsSaga);
    yield takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkTimeOutSaga);
    yield takeEvery(actionTypes.AUTH_LOGOUT_INIT, logoutSaga);
    yield takeEvery(actionTypes.AUTH_CHECK_STATE, checkStateSaga);
    yield takeEvery(actionTypes.AUTH_SMS_SEND, smsSendSaga);
    // yield takeEvery(actionTypes.AUTH_LOGIN_INIT, loginSaga);
}