import React from "react";
import { imagePlaceholder, baseURL } from "../../shared/variables";

const Image = ({ src, alt = "image", className = "", children }) => {
    return (
        <img
            src={`${baseURL}/${src}` || imagePlaceholder}
            alt={alt}
            className={className}
            onError={e => {
                e.target.src = imagePlaceholder;
            }}
        ></img>
    );
};

export default Image;
