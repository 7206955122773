export const AUTH_REGISTER_INIT = 'REGISTER_INIT';
export const AUTH_REGISTER_START = 'REGISTER_START';
export const AUTH_REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const AUTH_REGISTER_FAIL = 'REGISTER_FAIL';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_LOGOUT_INIT = 'AUTH_LOGOUT_INIT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_REGISTER_SMS_INIT = 'AUTH_REGISTER_SMS_INIT';
export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR';
export const AUTH_SMS_SEND = 'AUTH_SMS_SEND';
export const AUTH_REGISTER_FINISH = 'AUTH_REGISTER_FINISH';
// export const AUTH_LOGIN_INIT = 'AUTH_LOGIN_INIT';
// export const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
