import React from 'react';
// import {NavLink} from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';

const navigationItem = ({
                            link,
                            showMenu,
                            exact,
                            children,
                            closeToolbar
                        }) => {

    return (
        <li className={["Toolbar__item", showMenu ? "show" : ""].join(' ')}>
            <NavLink
                to={link}
                onClick={
                    closeToolbar
                }
                smooth
                className={"Toolbar__link"}
                activeClassName={'Toolbar__link--active'}
                exact={exact}>
                {children}
            </NavLink>
        </li>
    )
};

export default navigationItem;