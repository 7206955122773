import React from 'react';

const drawerToggle = ({
                          showMenu,
                          clicked
                      }) => {
    return (
        <div className={["Drawer", showMenu ? "close" : ""].join(' ')} onClick={clicked}>
            <div className="Drawer__line"/>
            <div className="Drawer__line"/>
            <div className="Drawer__line"/>
        </div>
    )
};

export default drawerToggle;