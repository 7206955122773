import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImages} from "@fortawesome/free-solid-svg-icons";
import Slider from "../../Index/Slider";
import ReviewsItem from "../../Index/ReviewsItem";
import Modal from "../../UI/Modal/modalFullScreen/modalFullScreen";
// import CertItem from "../../Index/CertItem";
// import Slider from "../../Index/Slider";
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faSearch} from '@fortawesome/free-solid-svg-icons';
// import Modal from "../../UI/Modal/modalFullScreen/modalFullScreen";

const Sport = () => {
    const [modalShow, setModalShow] = useState(false);
    const [project, setProject] = useState(null);

    const showModal = (project_name) => {
        setProject(project_name);
        setModalShow(prevState => !prevState);
    };

    const closeModal = () => {
        setModalShow(false);
    };

    const generateSlider = () => {
        switch (project) {
            case "kz":
                return (
                    <Slider
                        custom
                        settings={{
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplaySpeed: 3000,
                            dots: true,
                            infinite: true,
                            centerMode: false
                        }}
                        header=""
                        className="Slider"
                        data={[

                            <ReviewsItem
                                key={4}
                                image="/assets/sport/kz_1.jpg"
                                heading="Чемпионат Республики Казахстан по пауэрлифтингу 3 4"
                            />,
                            <ReviewsItem
                                key={1}
                                image="/assets/sport/kz_3.png"
                                heading="Чемпионат Республики Казахстан по пауэрлифтингу 1"
                            />,
                            <ReviewsItem
                                key={2}
                                image="/assets/sport/kz_2.jpg"
                                heading="Чемпионат Республики Казахстан по пауэрлифтингу 2"
                            />,
                            <ReviewsItem
                                key={3}
                                image="/assets/sport/kz_4.png"
                                heading="Чемпионат Республики Казахстан по пауэрлифтингу 3"
                            />,
                        ]}
                    />
                );
            case "asia":
                return (
                    <Slider
                        custom
                        settings={{
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplaySpeed: 3000,
                            dots: true,
                            infinite: true,
                            centerMode: false
                        }}
                        header=""
                        className="Slider"
                        data={[
                            <ReviewsItem
                                key={1}
                                image="/assets/sport/asia_1.jpg"
                                heading="Чемпионат Азии по пауэрлифтингу 1"
                            />,
                            <ReviewsItem
                                key={2}
                                image="/assets/sport/asia_2.jpg"
                                heading="Чемпионат Азии по пауэрлифтингу 2"
                            />,
                        ]}
                    />
                );
            case "wc":
                return (
                    <Slider
                        custom
                        settings={{
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplaySpeed: 3000,
                            dots: true,
                            infinite: true,
                            centerMode: false
                        }}
                        header=""
                        className="Slider"
                        data={[

                            <ReviewsItem
                                key={1}
                                image="/assets/sport/wc_3.png"
                                heading="Чемпионат по становой тяге 1"
                            />,
                            <ReviewsItem
                                key={2}
                                image="/assets/sport/wc_2.jpg"
                                heading="Чемпионат по становой тяге 2"
                            />,
                            <ReviewsItem
                                key={3}
                                image="/assets/sport/wc_1.jpg"
                                heading="Чемпионат по становой тяге 3"
                            />,
                        ]}
                    />
                );
        }
    };

    return (
        <React.Fragment>
            <section className="Sport" id="sport">
                {modalShow && (
                    <Modal show={modalShow} closed={closeModal}>
                        <div className="Achievement__modal">
                            {generateSlider()}
                        </div>
                    </Modal>
                )}

                <div className="Inner-section">
                    <div className="section-heading">
                        <h1>Хобби</h1>
                        <h6>Люблю спорт!</h6>
                    </div>

                    <div className="Sport__inner">
                        <ul className="Sport__list">
                            <li className="Sport__item">
                                <div className="card">
                                    <div className="card-header"
                                         style={{"backgroundImage": "url(/assets/sport/kz_2.jpg)"}}>
                                        <img className="card-header__img" src={"/assets/sport/kz_2.jpg"}
                                             alt="Чемпионат Республики Казахстан по пауэрлифтингу"/>
                                    </div>
                                    <div className="card-content">
                                        {/*<div className="card-content__title">*/}
                                        {/*    WRPF*/}
                                        {/*</div>*/}
                                        <div className="card-content__desc">
                                            Чемпионат Республики Казахстан по пауэрлифтингу
                                        </div>
                                        <div className="card-content__achievement">
                                            1 место
                                        </div>
                                        <div className="card-resources">
                                            <ul className="card-resources__list">
                                                <li
                                                    onClick={() => showModal("kz")}
                                                    className="card-resources__item card-resources__item--gallery"
                                                >
                                                    <FontAwesomeIcon className="card-resources__icon" icon={faImages}/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="Sport__item">
                                <div className="card">
                                    <div className="card-content">
                                        {/*<div className="card-content__title">*/}
                                        {/*    WRPF*/}
                                        {/*</div>*/}
                                        <div className="card-content__desc">
                                            Чемпионат Азии по пауэрлифтингу
                                        </div>
                                        <div className="card-content__achievement">
                                            2 место
                                        </div>
                                        <div className="card-resources">
                                            <ul className="card-resources__list">
                                                <li
                                                    onClick={() => showModal("asia")}
                                                    className="card-resources__item card-resources__item--gallery"
                                                >
                                                    <FontAwesomeIcon className="card-resources__icon" icon={faImages}/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card-header"
                                         style={{"backgroundImage": "url(/assets/sport/asia_2.jpg)"}}>
                                        <img className="card-header__img" src={"/assets/sport/asia_2.jpg"}
                                             alt="Чемпионат Азии по пауэрлифтингу"/>
                                    </div>
                                </div>
                            </li>
                            <li className="Sport__item">
                                <div className="card">
                                    <div className="card-header"
                                         style={{"backgroundImage": "url(/assets/sport/wc_2.jpg)"}}>
                                        <img className="card-header__img" src={"/assets/sport/wc_2.jpg"}
                                             alt="Чемпионат по становой тяге"/>
                                    </div>
                                    <div className="card-content">
                                        {/*<div className="card-content__title">*/}
                                        {/*    WRPF*/}
                                        {/*</div>*/}
                                        <div className="card-content__desc">
                                            Чемпионат по становой тяге
                                        </div>
                                        <div className="card-content__achievement">
                                            3 место
                                        </div>
                                        <div className="card-resources">
                                            <ul className="card-resources__list">
                                                <li
                                                    onClick={() => showModal("wc")}
                                                    className="card-resources__item card-resources__item--gallery"
                                                >
                                                    <FontAwesomeIcon className="card-resources__icon" icon={faImages}/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </section>
        </React.Fragment>

    )
};

export default Sport;
