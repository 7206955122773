import * as actionTypes from './actionTypes';

export const authRegisterStart = () => {
    return {
        type: actionTypes.AUTH_REGISTER_START
    }
};

export const authRegisterFinish = () => {
    return {
        type: actionTypes.AUTH_REGISTER_FINISH
    }
};

export const authRegisterSuccess = (userID, token, userData) => {
    return {
        type: actionTypes.AUTH_REGISTER_SUCCESS,
        userID,
        token,
        userData
    }
};

export const authRegisterFail = (error) => {
    return {
        type: actionTypes.AUTH_REGISTER_FAIL,
        error
    }
};

export const authCheckTimeout = (expirationTime) => {
    return {
        type: actionTypes.AUTH_CHECK_TIMEOUT,
        expirationTime
    }
};

export const authLogout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT_INIT,
    }
};

export const authLogoutSuccess = () => {
    return {
        type: actionTypes.AUTH_LOGOUT,
    }
};

export const authRegister = (formData) => {
    return {
        type: actionTypes.AUTH_REGISTER_INIT,
        formData
    }
};

// export const authRegisterBySms = (formData) => {
//     return {
//         type: actionTypes.AUTH_REGISTER_SMS_INIT,
//         formData
//     }
// };

export const authCheckState = () => {
    return {
        type: actionTypes.AUTH_CHECK_STATE
    }
};

export const clearAuthError = () => {
    return {
        type: actionTypes.AUTH_CLEAR_ERROR
    }
};

export const authSmsSend = (formData) => {
    return {
        type: actionTypes.AUTH_SMS_SEND,
        formData
    }
};

// export const authLogin = (formData) => {
//     return {
//         type: actionTypes.AUTH_LOGIN_INIT,
//         formData
//     }
// };
//
// export const authLoginStart = () => {
//     return {
//         type: actionTypes.AUTH_LOGIN_START
//     }
// };