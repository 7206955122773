import React from "react";
import Typed from 'react-typed';

const Main = () => {
    return (
        <section className="Main section" id="main">
            <div className="Main__drop" />
            <div className="Main__description">
                <h1 className="lg-heading">
                    <Typed
                        strings={[
                            '<strong>I</strong>liev<strong> T</strong>elman',
                            '<strong>I</strong><strong>T</strong>',
                            // '<strong>I</strong><strong>T</strong>',
                            // '<strong>I</strong>liev<strong> T</strong>elman',
                            '<strong>telman.io</strong>',

                        ]}
                        typeSpeed={100}
                        backSpeed={100}
                    />
                </h1>
                <h2 className="sm-heading">
                    Резидент Astana HUB. Член жюри Dar Lab. CTO в Startup Edavoda.kz
                </h2>
            </div>
        </section>
    )
};

export default Main;
